import React from "react";
import { useLocation } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { AiFillDashboard } from "react-icons/ai";
import { BiSolidBookContent } from "react-icons/bi";
import { FaUserAlt, FaUsersCog } from "react-icons/fa";
import PropTypes from "prop-types";
import { Box, Drawer, Hidden, List, ListSubheader } from "@mui/material";
import NavItem from "./NavItem";

const renderNavItems = ({ items, pathname, depth = 0, setSelectedTab }) => (
  <List disablePadding>
    {items?.map((item) => {
      const key = item.title + depth;
      const isActive = pathname.includes(item.href);
      return item.items ? (
        <NavItem
          depth={depth}
          icon={item.icon}
          info={item.info}
          key={key}
          open={Boolean(isActive)}
          title={item.title}
          isActive={isActive}
        >
          {renderNavItems({
            depth: depth + 1,
            pathname,
            items: item.items,
            setSelectedTab,
          })}
        </NavItem>
      ) : (
        <NavItem
          depth={depth}
          href={item.href}
          icon={item.icon}
          info={item.info}
          key={key}
          title={item.title}
          setSelectedTab={(item) => setSelectedTab(item)}
          isActive={isActive}
        />
      );
    })}
  </List>
);

const useStyles = (theme) => ({
  mobileDrawer: {
    width: 256,
    background: "#F56D21",
    position: "fixed",
  },
  desktopDrawer: {
    '& .MuiPaper-root': {
      backgroundColor: "red",
    },
    zIndex: "1",
    marginTop: "70px !important",
    top: "76px",
    width: "250px",
    height: "calc(100% - 125px)",
    margin: "5px 10px 10px 15px",
    background: "#F56D21",
    position: "fixed",
    boxShadow: "0px 0px 53px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
    marginLeft: "13px",
  },
});

const NavBar = ({ onMobileClose, openMobile, setSelectedTab }) => {
  const classes = useStyles();
  const location = useLocation();

  const sections = [
    {
      items: [
        {
          title: "Dashboard",
          icon: AiFillDashboard,
          href: "/dashboard",
          tabview: "Arbitrage",
        },
        {
          title: "User Management",
          icon: FaUserAlt,
          items: [
            {
              title: "Customer Management",
              icon: FaUsersCog,
              href: "/customer",
              tabview: "Arbitrage",
            },
            {
              title: "Business Management",
              icon: FaUsersCog,
              href: "/business",
              tabview: "Arbitrage",
            },
            {
              title: "Sub-Admin Management",
              icon: FaUsersCog,
              href: "/subadmin",
              tabview: "Arbitrage",
            },
          ],
        },
        {
          title: "Prefrence Menu Management",
          icon: AiFillDashboard,
          href: "/prefrencemenu",
          tabview: "Arbitrage",
        },
        {
          title: "Venue Management",
          icon: BiSolidBookContent,
          href: "/venuelisting",
          tabview: "Arbitrage",
        },
        {
          title: "Flash Offer Management",
          icon: BiSolidBookContent,
          href: "/spontaneouslisting",
          tabview: "Arbitrage",
        },
        {
          title: "Offers Management",
          icon: BiSolidBookContent,
          href: "/offerlisting",
          tabview: "Arbitrage",
        },
        {
          title: "Feedback Management",
          icon: BiSolidBookContent,
          href: "/feedbacklisting",
          tabview: "Arbitrage",
        },
        {
          title: "Content Management",
          icon: BiSolidBookContent,
          href: "/contentlisting",
          tabview: "Arbitrage",
        },
        {
          title: "My Account",
          icon: AiFillDashboard,
          href: "/profile",
          tabview: "Arbitrage",
        },
      ],
    },
  ];

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box pt={2} pb={2}>
          <Box className="sideMenuBox">
            {sections.map((section, i) => (
              <List
                key={`menu${i}`}
                subheader={
                  <ListSubheader disableGutters disableSticky>
                    {section.subheader}
                  </ListSubheader>
                }
              >
                {renderNavItems({
                  items: section.items,
                  pathname: location.pathname,
                  setSelectedTab,
                })}
              </List>
            ))}
          </Box>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          open={openMobile}
          onClose={onMobileClose}
          variant="temporary"
          sx={{
            '& .MuiPaper-root': {
              backgroundColor: "#ffffff", 
              color: "#1a237e", 
            },
          }}
          
        >
          <Box pt={2} pb={2}>{content}</Box>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
          sx={{
            '& .MuiPaper-root': {
              marginTop: "89px !important",
              backgroundColor: "#ffffff",
              color: "#1a237e",
              // boxShadow: "0px 0px 53px rgba(0, 0, 0, 0.25)",
              borderRadius: "10px",
              width: "250px",
              height: "calc(100% - 125px)",
              margin: "5px 4px 10px 8px",
              position: "fixed",
            },
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  setSelectedTab: PropTypes.func.isRequired,
};

export default NavBar;
