import React, { useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import {
  IconButton,
  SvgIcon,
  Toolbar,
  AppBar,
  Hidden,
  Grid,
  Box,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
} from "@mui/material";
import { Menu as MenuIcon } from "react-feather";
import { Link, useNavigate } from "react-router-dom";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { RxCrossCircled } from "react-icons/rx";

// Styles
const ToolbarStyled = styled(Toolbar)(({ theme }) => ({
  padding: "7px 30px",
  background: "#F87795",
  minHeight: "50px",
  [theme.breakpoints.down("sm")]: {
    padding: "0px 20px 0px 5px",
  },
  "& .MuiAppBar-positionFixed": {
    position: "unset",
  },
}));

const MainHeader = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  "& svg": {
    color: theme.palette.text.primary,
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
    },
  },
  "& .leftBox": {
    width: "100%",
    maxWidth: "42px",
    [theme.breakpoints.down("md")]: {
      maxWidth: "42px",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "42px",
    },
    "& img": {
      width: "100%",
      maxWidth: "42px",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "42px",
        paddingLeft: "0 !important",
        paddingTop: "8px",
        paddingBottom: "8px",
      },
    },
  },
}));

const useStyles = (theme) => ({
  mobileDrawer: {
    width: 256,
    background: "#F56D21",
    position: "fixed",
  },
  desktopDrawer: {
    "& .MuiPaper-root": {
      backgroundColor: "red",
    },
    zIndex: "1",
    marginTop: "70px !important",
    top: "76px",
    width: "250px",
    height: "calc(100% - 125px)",
    margin: "5px 10px 10px 15px",
    background: "#F56D21",
    position: "fixed",
    boxShadow: "0px 0px 53px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
    marginLeft: "13px",
  },
  logoutButton: {
    color: "#fff",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    position: "absolute",
    bottom: "5px",
    left: "17px",
    background: "#fff",
    fontWeight: "400",
    fontSize: "13px",
  },
  dialog: {
    "& .MuiDialog-paper": {
      background: "white !important",
    },
  },
  logoutimgicon: {
    border: "1px solid white",
    background: "white",
    color: "red",
    width: "100px",
    height: "70px",
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TopBar = ({
  selectedTab,
  onTabChange,
  className,
  onMobileNavOpen,
  ...rest
}) => {
  // Placeholder for theme setting and auth
  const themeMode = "LIGHT"; // Replace this with logic to determine theme mode
  const navigate = useNavigate();
  const classes = useStyles();
  const [isLogout, setIsLogout] = useState(false);
  const [open, setOpen] = useState(false);

  const handleLogOut = () => {
    sessionStorage.removeItem("adminToken");
    navigate("/");
  };

  return (
    <AppBar
      elevation={0}
      className={className}
      color="inherit"
      sx={{ boxShadow: "0px 4px 4px rgb(0 0 0 / 10%)" }}
      {...rest}
    >
      <ToolbarStyled>
        {window.location.pathname === "/" ||
        window.location.pathname === "/forget" ||
        window.location.pathname === "/verify-otp" ||
        window.location.pathname === "/reset-password" ? null : (
          <Hidden mdUp>
            <IconButton
              color="white"
              onClick={onMobileNavOpen}
              sx={{ padding: "0px" }}
            >
              <SvgIcon>
                <MenuIcon style={{ color: "white", fontSize: "25px" }} />
              </SvgIcon>
            </IconButton>
          </Hidden>
        )}
        &nbsp; &nbsp;
        <MainHeader>
          <Grid container alignItems="center">
            <Grid item lg={3} md={3} sm={4} xs={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  "& img": {
                    cursor: "pointer",
                    width: "60%",
                    "@media (min-width:1440px)": {
                      width: "40%",
                    },
                  },
                }}
              >
                <Typography
                  variant="h4"
                  color="#FFFFFF"
                  style={{ flexGrow: 1 }}
                >
                  Cambeo Admin Panel
                </Typography>
                <Link to="/dashboard" style={{ textDecoration: "none" }}></Link>
              </Box>
            </Grid>
            <Grid
              sx={{ display: "flex", justifyContent: "end" }}
              item
              lg={9}
              md={9}
              sm={8}
              xs={6}
            >
              <Button
                onClick={() => setIsLogout(true)}
                style={{ color: "white", textAlign: "center" }}
              >
                <ExitToAppIcon sx={{ fontSize: "32px" }} />
              </Button>
              {isLogout && (
                <Dialog
                  fullWidth
                  maxWidth="xs"
                  className={classes.dialog}
                  open={isLogout}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={() => setIsLogout(false)}
                >
                  <DialogContent>
                    <Box className={classes.dialougTitle} align="center">
                      <Box>
                        <RxCrossCircled className={classes.logoutimgicon} />
                      </Box>
                      <Typography
                        variant="h4"
                        color="primary"
                        style={{ marginTop: "20px", color: "black" }}
                      >
                        LOGOUT
                      </Typography>
                      <Typography
                        variant="h6"
                        color="primary"
                        style={{ marginTop: "20px", color: "black" }}
                      >
                        Are you sure you want to logout?
                      </Typography>
                    </Box>
                  </DialogContent>
                  <DialogActions
                    style={{ alignContent: "center", justifyContent: "center" }}
                  >
                    <Box mt={2} style={{ marginTop: "20px" }}>
                      <Button

                        color="secondary"
                        style={{
                          padding: "8px 21px",
                          background: "white",
                          border: "1px solid black",
                        }}
                        onClick={() => setIsLogout(false)}
                      >
                        No
                      </Button>
                      &nbsp; &nbsp;
                      <Button
              
                        color="secondary"
                        style={{
                          padding: "8px 21px",
                          background: "white",
                          border: "1px solid black",
                        }}
                        onClick={handleLogOut}
                      >
                        Yes
                      </Button>
                    </Box>
                  </DialogActions>
                </Dialog>
              )}
            </Grid>
            <Hidden xsDown>
              <Grid lg={4} md={6} sm={5} />
            </Hidden>
            <Grid lg={5} md={3} sm={3} xs={6}></Grid>
          </Grid>
        </MainHeader>
      </ToolbarStyled>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  selectedTab: PropTypes.string,
  onTabChange: PropTypes.func,
  onMobileNavOpen: PropTypes.func,
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;
