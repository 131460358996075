import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

const Root = styled('div')(({ theme }) => ({
  alignItems: "center",
  backgroundColor: "#ffffff",
  display: "flex",
  flexDirection: "column",
  height: "100vh",
  justifyContent: "center",
  zIndex: 2000,
}));

const ProgressBar = styled('div')({
  height: "3px",
});

export default function PageLoading() {
  return (
    <Root>
      <Box sx={{ backgroundColor: '#F87795', padding: '10px' }}>
        <Typography 
          variant="h4" 
          sx={{
            color: '#FFFFFF',
            textAlign: 'center',
            fontWeight: 'bold',
          }}
        >
          Cambeo
        </Typography>
      </Box>
      <ProgressBar />
    </Root>
  );
}
