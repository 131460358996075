import React from 'react';
import { Container, Box, Typography, AppBar, Toolbar, CssBaseline } from '@mui/material';
import { styled } from '@mui/material/styles';

const Header = styled(AppBar)(({ theme }) => ({
  background: theme.palette.primary.main,
  position: 'sticky',
}));

const Footer = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.main,
  padding: theme.spacing(2),
  textAlign: 'center',
}));

const Content = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const LoginLayout = ({ children }) => {
  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <CssBaseline />
      <Header>
        <Toolbar>
          <Typography variant="h6" color="#FFFFFF" style={{ flexGrow: 1 }}>
            Cambeo
          </Typography>
        </Toolbar>
      </Header>
      <Content>
        <Container maxWidth="lg" sx={{ width: '100%', maxWidth: '1600px' }}> 
          {children}
        </Container>
      </Content>
      <Footer>
        <Typography variant="body2" color="textPrimary">
          © {new Date().getFullYear()} Admin panel Developed By Mobiloitte Technologies
        </Typography>
      </Footer>
    </Box>
  );
};

export default LoginLayout;
