import { lazy } from "react";
import LoginLayout from "./layouts/LoginLayout";
import DashboardLayout from "./layouts/DashboardLayout/index.js"

export const routes = [
  {
    exact: true,
    path: "/",
    layout: LoginLayout,
    component: lazy(() => import("./views/pages/AuthFlow/Login.js")),
  },
  {
    exact: true,
    path: "/forgot",
    layout: LoginLayout,
    component: lazy(() => import("./views/pages/AuthFlow/ForgotPassword.js")),
  },
  {
    exact: true,
    path: "/otpVerification",
    layout: LoginLayout,
    component: lazy(() => import("./views/pages/AuthFlow/OTPVerification.js")),
  },
  {
    exact: true,
    path: "/resetPassword",
    layout: LoginLayout,
    component: lazy(() => import("./views/pages/AuthFlow/ResetPassword.js")),
  },
  {
    exact: true,
    path: "/dashboard",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/Dashboard.js")),
  },
  {
    exact: true,
    path: "/customer",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/UserManagement/CustomerManagement/CustomerListing.js")),
  },
  {
    exact: true,
    path: "/customer/viewcustomer",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/UserManagement/CustomerManagement/ViewCustomer.js")),
  },
  {
    exact: true,
    path: "/business",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/UserManagement/BusinessManagement/BusinessListing.js")),
  },
  {
    exact: true,
    path: "/business/viewbusiness",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/UserManagement/BusinessManagement/ViewBusiness/ViewBusiness.js")),
  },
  {
    exact: true,
    path: "/business/addbusinessaccount",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/UserManagement/BusinessManagement/AddBusinessAccount.js")),
  },
  {
    exact: true,
    path: "/business/editbusinessaccount",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/UserManagement/BusinessManagement/EditBusinessAcount.js")),
  },
  {
    exact: true,
    path: "/business/viewnewbussiness",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/UserManagement/BusinessManagement/ViewNewRequest/ViewRequestListing.js")),
  },
  {
    exact: true,
    path: "/business/viewnewbussiness/viewdetails",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/UserManagement/BusinessManagement/ViewNewRequest/viewNewRequest.js")),
  },
  {
    exact: true,
    path: "/business/viewnewbussiness/viewrejectedetails",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/UserManagement/BusinessManagement/ViewNewRequest/ViewRejectedRequest.js")),
  },
  {
    exact: true,
    path: "/subadmin",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/UserManagement/SubadminManagement/SubAdminListing.js")),
  },
  {
    exact: true,
    path: "/subadmin/addsubadmin",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/UserManagement/SubadminManagement/AddSubAdmin.js")),
  },
  {
    exact: true,
    path: "/subadmin/editsubadmin",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/UserManagement/SubadminManagement/EditSubAdmin.js")),
  },
  {
    exact: true,
    path: "/subadmin/viewsubadmin",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/UserManagement/SubadminManagement/ViewSubAdmin.js")),
  },
  {
    exact: true,
    path: "/prefrencemenu",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/PreferencesMenuManagement/PrefrenceMenu.js")),
  },
  {
    exact: true,
    path: "/prefrencemenu/addcategory",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/PreferencesMenuManagement/Category/AddCategory.js")),
  },
  {
    exact: true,
    path: "/prefrencemenu/editcategory",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/PreferencesMenuManagement/Category/EditCategory.js")),
  },
  {
    exact: true,
    path: "/prefrencemenu/addcuisine",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/PreferencesMenuManagement/Cuisine/Addcuisine.js")),
  },
  {
    exact: true,
    path: "/prefrencemenu/editcuisine",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/PreferencesMenuManagement/Cuisine/EditCuisine.js")),
  },
  {
    exact: true,
    path: "/prefrencemenu/adddish",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/PreferencesMenuManagement/DishesMenu/AddDishes.js")),
  },
  {
    exact: true,
    path: "/prefrencemenu/editdish",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/PreferencesMenuManagement/DishesMenu/EditDishes.js")),
  },
  {
    exact: true,
    path: "/prefrencemenu/adddrink",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/PreferencesMenuManagement/DrinkMenu/AddDrink.js")),
  },
  {
    exact: true,
    path: "/prefrencemenu/editdrink",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/PreferencesMenuManagement/DrinkMenu/EditDrinks.js")),
  },
  {
    exact: true,
    path: "/prefrencemenu/addfoodoptions",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/PreferencesMenuManagement/ActivityOptions/AddActivityOptions.js")),
  },
  {
    exact: true,
    path: "/prefrencemenu/editfoodoptions",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/PreferencesMenuManagement/ActivityOptions/EditActivityOptions.js")),
  },
  {
    exact: true,
    path: "/venuelisting",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/VenueManagement/VenueListing.js")),
  },
  {
    exact: true,
    path: "/venuelisting/viewvenue",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/VenueManagement/ViewVenue.js")),
  },
  {
    exact: true,
    path: "/venuelisting/viewnewvenue",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/VenueManagement/NewVenueRequest/NewVenueRequestListing.js")),
  },
  {
    exact: true,
    path: "/venuelisting/viewvenue/viewoffer",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/VenueManagement/VenueDetails/ViewOffer.js")),
  },
  {
    exact: true,
    path: "/spontaneouslisting",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/SpontaneousOfferManagement/SpontaneousListing.js")),
  },
  {
    exact: true,
    path: "/spontaneouslisting/addspontaneousoffer",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/SpontaneousOfferManagement/AddSpontaneousOffer.js")),
  },
  {
    exact: true,
    path: "/spontaneouslisting/viewspontaneousoffer",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/SpontaneousOfferManagement/ViewSpontaneousOffer.js")),
  },
  {
    exact: true,
    path: "/offerlisting",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/OfferManagement/OfferListing.js")),
  },
  {
    exact: true,
    path: "/offerlisting/viewoffer",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/OfferManagement/ViewOffer.js")),
  },
  {
    exact: true,
    path: "/offerlisting/viewnewoffer",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/OfferManagement/NewOfferRequestListing/NewOfferRequestLising.js")),
  },
  {
    exact: true,
    path: "/offerlisting/viewnewoffer/viewrejectedoffer",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/OfferManagement/NewOfferRequestListing/ViewRejectedOffer.js")),
  },
  {
    exact: true,
    path: "/offerlisting/viewnewoffer/viewpendingoffer",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/OfferManagement/NewOfferRequestListing/ViewPendingOffer.js")),
  },
  {
    exact: true,
    path: "/contentlisting",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/StaticContentManagement/StaticContentListing.js")),
  },
  {
    exact: true,
    path: "/contentlisting/privacypolicy",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/StaticContentManagement/PrivacyPolicy/PrivacyPolicy.js")),
  },
  {
    exact: true,
    path: "/contentlisting/editPrivacyPolicy",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/StaticContentManagement/PrivacyPolicy/EditPrivacyPolicy.js")),
  },
  {
    exact: true,
    path: "/contentlisting/aboutus",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/StaticContentManagement/AboutUs/AboutUs.js")),
  },
  {
    exact: true,
    path: "/contentlisting/editaboutus",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/StaticContentManagement/AboutUs/EditAboutUs.js")),
  },
  {
    exact: true,
    path: "/contentlisting/tc",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/StaticContentManagement/TermsAndCondition/TermsAndCondition.js")),
  },
  {
    exact: true,
    path: "/contentlisting/edittc",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/StaticContentManagement/TermsAndCondition/EditTermAndCondition.js")),
  },
  {
    exact: true,
    path: "/contentlisting/faq",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/StaticContentManagement/FAQ/FAQ.js")),
  },
  {
    exact: true,
    path: "/contentlisting/faq/addfaq",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/StaticContentManagement/FAQ/AddFQA.js")),
  },
  {
    exact: true,
    path: "/contentlisting/faq/editfaq",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/StaticContentManagement/FAQ/EditFAQ.js")),
  },
  {
    exact: true,
    path: "/feedbacklisting",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/FeedbackMangement.js/FeedbackListing.js")),
  },
  {
    exact: true,
    path: "/feedbacklisting/viewfeedback",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/FeedbackMangement.js/ViewFeedBack.js")),
  },
  {
    exact: true,
    path: "/profile",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Setting/ManageProfile.js")),
  },
  {
    exact: true,
    path: "/profile/editprofile",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Setting/EditProfile.js")),
  },
  {
    exact: true,
    path: "/profile/changepassword",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Setting/ChangePassword.js")),
  },
];
